export const notificationTypes = [
    {
        id:'NEWNESS',
        text:'Noticia'
    },
    {
        id:'PROMOTION',
        text:'Promoción'
    }
]

export const pipeText = (key) => {
    const value =  notificationTypes.find(item => item.id === key );
    return value ? value.text: '-';
}
