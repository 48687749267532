import axios from 'axios'
import env from  '../envioments'

 export  class Master {

    find( name, filter = {}) {
         return axios.get(`${env.remoteUrl}/master/${name}`, {params: filter})
            .then(res => res.data);
    }

}

export default new Master();
