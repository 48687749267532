<template>
    <div id="content">
        <DataTable :value="data" class="p-datatable-responsive"  selectionMode="single" dataKey="id" :paginator="true" :rows="filters.perPage" :filters="filters"
                   :totalRecords="filters.totalRows" :loading="loading" @page="onPage($event)" :lazy="true"
                   :scrollable="true"
                   scrollHeight="calc(100vh - 310px)"
                   :stickyHeader="true"
                   id="table"
        >
            <template #empty>
                <h6 class="text-center mt-2" v-if="!loading">No se encontraron registros que coincidan con la búsqueda</h6>
                <h6 class="text-center mt-2"  v-else>Buscando</h6>
            </template>
            <template #header>
                <div class="d-flex justify-content-between">
                    <h5 class="text-left">Notificaciones masivas</h5>

                    <div style="text-align:left">
                        <Button abel="Agregar" icon="pi pi-plus" class="btn-company" @click="onSelectedRow({})" />
                        <Button title="Buscar" icon="pi pi-search" @click="reload()" class="mx-2"/>

                    </div>
                </div>
            </template>
            <Column field="title" header="Titulo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Titulo</span>
                    {{slotProps.data.title}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.title" class="p-column-filter" placeholder="Buscar por tipo de consulta" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="content" header="Contenido" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Contenido</span>
                    {{slotProps.data.content}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.content" class="p-column-filter" placeholder="contenido" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="user_selection_method" header="Canal" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Canal</span>
                    {{slotProps.data.user_selection_method | selectionMethodText}}
                </template>
                <template #filter>
                    <b-form-select  v-model="filters.user_selection_method" :options="userSelectionMethods"
                                    value-field="id"
                                    text-field="text"
                                    @change="reload()"

                    ></b-form-select>
                </template>
            </Column>
            <Column field="type" header="Tipo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Canal</span>
                    {{slotProps.data.type | noticeTypeText}}
                </template>
                <template #filter>
                    <b-form-select  v-model="filters.type" :options="noticesTypes"
                                    value-field="id"
                                    text-field="text"
                                    @change="reload()"

                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null"  >Todos</b-form-select-option>
                        </template>

                    </b-form-select>
                </template>
            </Column>
            <Column field="start_at" header="Inicio" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Canal</span>
                    {{slotProps.data.start_at | moment("DD/MM/YYYY hh:mm a") }}
                </template>
                <template #filter>
                    <Calendar :showButtonBar="true" :locale="es" dateFormat="dd/mm/yy"    hourFormat="12" appendTo="body" @date-select="changeDate($event)" @clear-click="changeDate(null)"/>


                </template>
            </Column>


            <Column field="id" header="Acciones" :sortable="false" headerStyle="width: 6rem" bodyClass="action">
                <template #body="slotProps" class="tex">
                    <Button title="Editar" icon="pi pi-pencil" @click="onSelectedRow(slotProps.data)" class="m-auto"/>
                    <Button title="Eliminar" icon="pi pi-times"    @click="onDeletedRow(slotProps.data)" class="m-auto p-button-danger"/>

                </template>
                <template #filter>
                    <button class="btn btn-company btn-sm" @click="clearFilters()">limpiar</button>

                </template>

            </Column>

        </DataTable>
        <Dialog :visible.sync="showForm" :style="{width: '800px'}" :header="titleForm" :modal="true">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)" ref="form" name="form">

                    <div class="p-cardialog-content" >
                        <b-container  style="min-height:50vh">
                            <b-row v-show="step === 1">
                                <b-col lg="6">
                                    <b-container class="py-4">

                                        <b-row class="mb-3">
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="titulo" :rules="{ required: true}"
                                                                        v-slot="validationContext" >
                                                        <div class="p-float-label">
                                                            <InputText id="title" type="text" v-model="model.title"  class="w-100"/>
                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">
                                                                {{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                            <label for="title">Titulo</label>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>


                                        <b-row class="mb-3">
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="canal" :rules="{ required: true }"
                                                                        v-slot="validationContext" >
                                                        <div class="">
                                                            <label >Canal</label>

                                                            <b-form-select  v-model="model.user_selection_method"
                                                                            :options="userSelectionMethods"
                                                                            id="user_selection_method"
                                                                            value-field="id"
                                                                            text-field="text"
                                                                            @change="changeMethod(model.user_selection_method)"
                                                            ></b-form-select>
                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-3">
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="tipo" :rules="{ required: true}"
                                                                        v-slot="validationContext" >
                                                        <div class="">
                                                            <label>Tipo</label>

                                                            <b-form-select  v-model="model.type" :options="noticesTypes" id="noti-type"
                                                                            value-field="id"
                                                                            text-field="text"
                                                            ></b-form-select>
                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-3">
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="contenido" :rules="{ required: true, max: 149}"
                                                                        v-slot="validationContext" >
                                                        <div >
                                                            <label for="noti-content">Contenido</label>

                                                            <Textarea id="noti-content" type="text" v-model="model.content"  class="w-100" :rows="3" maxLength="149"/>
                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>


                                    </b-container>
                                </b-col>
                                <b-col lg="6">
                                    <b-container>

                                        <label>Fecha de Envío: </label>
                                        <b-row >
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="Canal" :rules="{ required: true}"
                                                                        v-slot="validationContext" >
                                                        <div class="p-float-label">
                                                            <Calendar id="start_at" v-model="model.start_at" :locale="es" dateFormat="dd/mm/yy" :showTime="true" :baseZIndex="5"  hourFormat="12" appendTo="content"/>

                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row >
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="Recurrente" :rules="{ required: true}"
                                                                        v-slot="validationContext" >
                                                        <div  >
                                                            <Checkbox id="binary" v-model="model.is_repeat" :binary="true" />
                                                            <label for="binary" class="my-auto mx-2">Recurrente</label>

                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <label v-show="model.is_repeat">Días de envío: </label>

                                        <b-row v-show="model.is_repeat">
                                            <b-col>
                                                <div class="form-group ">
                                                    <ValidationProvider name="dias" :rules="{ required_if: model.is_repeat}"
                                                                        v-slot="validationContext" >
                                                        <div  class="d-flex justify-content-between flex-wrap" >
                                                            <div class="mx-2">
                                                                <Checkbox id="day1" inputId="day1" name="days" value="1" v-model="days" />
                                                                <label  class="my-auto mx-2" for="day1">Lunes</label>

                                                            </div>


                                                            <div  class="mx-2">
                                                                <Checkbox id="day2" inputId="day2" name="day" value="2" v-model="days" />
                                                                <label  class="my-auto mx-2"  for="day2">Martes</label>

                                                            </div>
                                                            <div  class="mx-2">
                                                                <Checkbox id="day3" inputId="day3" name="day" value="3" v-model="days" />
                                                                <label  class="my-auto mx-2">Miércoles</label>

                                                            </div>
                                                            <div  class="mx-2">
                                                                <Checkbox id="day4" inputId="day4" name="day" value="4" v-model="days" />
                                                                <label  class="my-auto mx-2">Jueves</label>

                                                            </div>
                                                            <div  class="mx-2">
                                                                <Checkbox id="day5" inputId="day5" name="day" value="5" v-model="days" />
                                                                <label  class="my-auto mx-2" >Viernes</label>

                                                            </div>
                                                            <div  class="mx-2">
                                                                <Checkbox id="day6" inputId="day6" name="day" value="6" v-model="days" />
                                                                <label  class="my-auto mx-2">Sábado</label>

                                                            </div>

                                                            <div  class="mx-2">
                                                                <Checkbox id="day7" inputId="day7" name="day" value="7" v-model="days" />
                                                                <label class="my-auto mx-2">Domingo</label>

                                                            </div>


                                                            <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                                        </div>

                                                    </ValidationProvider>
                                                </div>
                                            </b-col>
                                        </b-row>

                                    </b-container>

                                </b-col>
                            </b-row>
                            <b-row class="mb-3" v-show="step === 2">
                                <b-col>
                                    <div class="form-group ">
                                        <ValidationProvider name="usuarios" :rules="{  }"
                                                            v-slot="validationContext" >
                                            <div class="">
                                                <label for="noti-content">Usuarios</label>

                                                <multiselect v-model="model.personals" :options="users"  label="title"

                                                             track-by="id" :multiple="true"  :max-height="120" :selectLabel="'Pulse para seleccionar'" selectedLabel="selecionado" deselectLabel="selecione para quitar">
                                                    <template slot="placeholder">
                                                        Selecionar usuario
                                                    </template>
                                                </multiselect>

                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>

                    </div>


                </b-form>
            </validation-observer>
            <template #footer>

                <Button v-if="requiredUser" :label="step === 1 ? 'Siguiente':'Atras'" :icon="step !== 1  ? 'pi pi-arrow-left' :'pi  pi-arrow-right'"  class="p-button-info" @click="step === 1  ? step = 2: step = 1;" :disabled="loading"   />

                <Button :label="!loading ? 'Guardar':'Guardando'" :icon="loading ? 'pi pi-spin pi-spinner' :'pi pi-check'"  class="p-button-success" @click="onSubmit()" :disabled="loading"   />
            </template>
        </Dialog>
        <Dialog header="Pregunta" :visible.sync="showDelForm"   :modal="true">
            ¿esta seguro de eliminar?
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="showDelForm = false" class="p-button-secondary"/>
                <Button  label="Si" icon="pi pi-check" @click="deleteItem()"/>
            </template>
        </Dialog>




    </div>


</template>

<script>

    import Crud from '../../service/Crud';
    import Master from '../../service/Master';
    import Dialog from 'primevue/dialog'
    import Calendar from 'primevue/calendar';
    import Checkbox from 'primevue/checkbox';
    import Textarea from 'primevue/textarea';
    import {channels, pipeText} from "../../constants/notificationsChannels";
    import {notificationTypes, pipeText as notyPipes} from "../../constants/notificationsTypes";
    import toDbDate from "../../tools/toDbDate";
    import removeNullable from "../../tools/removeNullable";
    import multiselect from 'vue-multiselect'
    import { BFormInvalidFeedback } from 'bootstrap-vue'


    export default {
        components: {
            Dialog,
            Calendar,
            Checkbox,
            Textarea,
            multiselect,
            BFormInvalidFeedback

        },
        filters: {
            selectionMethodText:pipeText,
            noticeTypeText:notyPipes
        },
        data() {
            return {
                step:1,
                days: [],
                requiredUser:false,
                users:[],
                es: {
                    firstDayOfWeek: 1,
                    dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
                    dayNamesShort: [ "Dom","Lun","Mar","Mié","Jue","Vie","Sáb" ],
                    dayNamesMin: [ "D","L","M","X","J","V","S" ],
                    monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
                    monthNamesShort: [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic" ],
                    today: 'Hoy',
                    clear: 'Borrar',
                    weekHeader: 'Sm'
                },
                filters: {
                    page:1,
                    perPage: 20,
                    totalRows:0,
                    is_active:-1
                },
                model:{
                    is_active:false,
                    is_repeat:false,
                    days:[],
                    personals:[]
                },
                loading:false,
                showForm:false,
                showDelForm:false,
                data: [],
                layout: 'list',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                crud: null,
                selected:null,
                userSelectionMethods: channels,
                noticesTypes: notificationTypes,
                status:[
                    {
                        id:true,
                        title:'Si'
                    },
                    {
                        id:false,
                        title:'No'
                    }
                ]
            }

        },
        created() {
            this.$moment.locale('es');
            this.crud = Crud('mass-notifications');
            this.crud.findAll(this.filters).then(res =>{
                this.data = res.data ;
                this.filters.totalRows = res.meta.total;
            });

            Master.find('employees').then((res) =>{
              this.users = res.data;
            })

        },
        methods: {
            clearFilters() {
                const omit  = ['perPage', 'totalRows', 'page'];
                Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
                this.reload();
            },
            changeMethod(value){
                // console.log(value);
                this.requiredUser = value === 'EXCLUDE' || value === 'SELECTED';
            },
            initForm(){
                this.model = {
                    is_active:false,
                    users:[]
                };
            },
            reload(){
                this.loading = true;
                this.crud.findAll(this.filters).then(res =>{
                    this.data = res.data ;
                    this.filters.totalRows = res.meta.total;
                    this.loading = false;

                });
            },
            onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
            onSubmit(){
                this.$refs.observer.validate();
                if(!this.$refs.observer.flags.invalid){
                    this.sendModelForm(this.model.id);

                }
            },
            onSelectedRow(item){
                this.step= 1;
                const selected =  Object.assign({}, item);
                this.days = selected.days && selected.days.length > 0 ?  selected.days : [];
                selected.is_repeat = !!(selected.days && selected.days.length > 0);
                const date = this.$moment(selected.start_at);
                selected.start_at = new Date(date.format('YYYY-MM-DD HH:mm:ss'));
                this.selected = selected;
                this.initForm();
                Object.assign(this.model, selected);
                this.changeMethod(item.user_selection_method);

                this.showForm = true

            },
            onDeletedRow(item){
                this.selected = item;
                this.showDelForm = true;
            },
            changeDate(event){
                this.filters.start_at = event ? toDbDate(event): null;
                this.reload();
            },
            deleteItem(){
                this.crud.delete( this.selected.id).then(()=>{
                    this.showDelForm = false;
                    this.reload();
                });
            },
            onSuccess(){
                this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
                this.loading = false;
                this.showForm = false;
                this.initForm();
                this.reload();

            },
            onError(response){
                this.$toast.add({severity:'error', summary: response.data.message, life: 3000});
                this.loading = false;
            },
            sendModelForm(id = null){
                let send = Object.assign({}, this.model);
                send.days = this.days.join(',');

                if(this.requiredUser) {
                    send.user_ids = '';
                    const ids = [];
                    if(!this.model.personals){
                        this.step = 2;
                        return;
                    }
                    this.model.personals.map(item => ids.push(item.id));
                    send.personal_ids  = ids.join(',');

                }else {
                    delete  send.personal_ids;
                }
                send = removeNullable(send);


                send.start_at = toDbDate(send.start_at)+ ' ' + ("0" + send.start_at.getHours()).slice(-2) + ':' +("0" + send.start_at.getMinutes()).slice(-2);
                this.loading = true;

                if(!id) {
                    this.crud.store(send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }else {
                    this.crud.update(id,send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }

            }

        },
        computed:{
            isEdit(){
                return this.selected && this.selected.id;
            },
            titleForm(){ return this.isEdit ? 'Editar': 'Nuevo' }

        }
    }
</script>

<style lang="scss">
    .p-dropdown-panel{
        margin-left: 15px;
    }
    .action{
        display: flex;
        > * {
            margin: auto;
        }

    }
</style>
<style lang="scss" scoped>

</style>
