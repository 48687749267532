export const channels = [
    {
        id:'ALL',
        text:'Todos'
    },
    {
        id:'ACTIVE',
        text:'Usuarios activos'
    },
    {
        id:'INACTIVE',
        text:'Usuarios inactivos'
    },
    {
        id:'SELECTED',
        text:'Solo seleccionados'
    },
    {
        id:'EXCLUDE',
        text:'Excluir selecionados'
    }
]

export const pipeText = (key) => {
    const value =  channels.find(item => item.id === key );
    return value ? value.text: '-';
}
